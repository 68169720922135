require('./common.js');



require('../style/jquery.fullPage.min.css');
require('../style/index.css');

require('../js/jquery.waypoints.min.js');
require('../js/jquery.countup.min.js');
require('../js/jquery.easing.1.3.js');
require('../js/jquery.fullPage.min.js');

// vod load 2023-12-11 14:22:51
const {loadVod} = require('./loadVod.js');

// console.log(loadVod);
(new loadVod("#index_vod"));


// 1s后播放视频
// setTimeout(()=>{
//   (new loadVod("#index_vod"));
//   //   document.querySelector(".banner-video").play();
// }, 1000);
