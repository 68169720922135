// 语言切换js
function language_switch(click_icon, show_hide_element) {
  $(click_icon).on("click", function (even) {
    $(show_hide_element).stop().slideToggle();

    even.stopPropagation();

    $(document).on("click", function () {
      $(show_hide_element).stop().slideUp();
    });

    $(show_hide_element).on("click", function (even) {
      even.stopPropagation();
    });
  });
}
// 排他
function table(click_title) {
  $(click_title).on("click", function () {
    $(this).addClass("hover").siblings().removeClass("hover");
  });
}
//公用搜索框的点击事件
function public_head_search_fun(click_element) {
  $(click_element).on("click", function () {
    $(".all_public_searchBox").stop().fadeIn();
    // $(".all_public_form").stop().fadeIn();
    $(".all_public_form").css("display","flex");
    
  });

  $(".all_public_searchBox").on("click", function () {
    $(".all_public_searchBox").stop().fadeOut();
    // $(".all_public_form").stop().fadeOut();
    $(".all_public_form").css("display","none");
  });

  $(".all_public_form .public_form_close").on("click", function () {
    $(".all_public_searchBox").stop().fadeOut();
    // $(".all_public_form").stop().fadeOut();
    $(".all_public_form").css("display","none");
  });
}
//点击按钮到达指定位置

function scrollFixed(obj1, obj2, obj3, obj4) {
  // obj1 要点击的item项
  // obj2 要悬浮起来的元素
  // obj3 要滑动到下边的item类名
  // obj4 悬浮起来的元素宽度
  var arr1 = [];
  var i = 0;
  var md = $(obj1);

  //获取下面对应的每块内容距离顶部的距离，放在一个数组里面
  $(obj3).each(function () {
    var pos = $(this).offset().top;
    arr1.push(pos);
  });

  var navOffsetTop = $(obj2).offset().top;
  var navOffsetH = $(obj2).innerHeight();
  var menuH = 0;
  var navWidth = $(obj4).width();

  //上边小按钮的点击事件
  md.each(function (num, val) {
    $(md[num]).click(function () {
      $("html,body")
        .stop(true, true)
        .animate(
          {
            scrollTop: arr1[num] - navOffsetH - menuH + 1 + "px",
          },
          600
        );
      //update();
    });
  });

  function update() {
    var scrollH = $(window).scrollTop();
    //滚动顶部固定
    if (scrollH > navOffsetTop - menuH) {
      $(obj2).css({
        position: "fixed",
        "z-index": "4",
        top: menuH,
        width: navWidth,
      });
    } else {
      $(obj2).css({
        position: "relative",
        top: "auto",
        width: "100%",
      });
    }
    //滚动导航高亮切换
    for (var num = 0; num < md.length; num++) {
      var mdHeight = arr1[num];
      if (mdHeight < scrollH + navOffsetH + menuH) {
        //var j = num+2;
        $(obj1).eq(num).addClass("hover").siblings().removeClass("hover");
      }
    }
    if (scrollH < arr1[0]) {
      //初始化的头部scrollTop值
      $(obj1).eq(0).addClass("hover").siblings().removeClass("hover");
    }
  }

  $(window).on("load resize scroll", function () {
    update();
  });
}
//选项卡
function tabs(tabTitle, tab_content) {
  var index = $(tabTitle).children(".hover").index();
  $(tab_content).children().eq(index).show().siblings().hide();
  $(tabTitle)
    .children()
    .click(function () {
      var index = $(this).index();
      console.log(index);
      $(this).addClass("hover").siblings().removeClass("hover");
      $(tab_content).children().eq(index).show().siblings().hide();
      return false;
    });
}
// 视频播放函数
function video_play_fun(click_item) {
  $(click_item).on("click", function () {
    var video_src = $(this).attr("data-src");
    $(".ind_video_maskBox .video_Box_1 .videoIframe").prop("src", video_src);
    $(".ind_video_maskBox").stop().fadeIn();
  });

  // 关闭按钮
  $(".ind_video_maskBox .video_Box_1 .closeBtn").on("click", function () {
    $(".ind_video_maskBox").stop().fadeOut();
    $(".ind_video_maskBox .video_Box_1 .videoIframe").prop("src", "");
  });
  // 遮罩层
  $(".ind_video_maskBox").on("click", function () {
    $(".ind_video_maskBox").stop().fadeOut();
    $(".ind_video_maskBox .video_Box_1 .videoIframe").prop("src", "");
  });
}
function FontSize(title) {
  $(title).each(function () {
    $(this).width($(this).width());
  });
}
$(function () {
  var w = $(window).width();
  var h = $(window).height();
  var sTop = document.documentElement.scrollTop + document.body.scrollTop;
  // product_list1 点击跳转指定位置
  $(window).scroll(function () {
    $(".soution_one_t .item").click(function (e) {
      if ($(e.target).attr("name") === "other") {
        $("html,body")
          .stop(true)
          .animate(
            {
              scrollTop:
                $(".soution_one_r .pro_det_scroll_item")
                  .eq($(e.target).index())
                  .offset().top - 20,
            },
            1000
          );
      }
    });

    var tops = $(window).scrollTop();
    if (tops > 1) {
      $(".header_t").slideUp();
      $(".header_b").addClass("hover");
      // $(".logo1").hide();
      // $(".logo2").show();
      $(".logo").removeClass("logo-white");
      $(".menu_dl").removeClass("menu_tt_shadow");

      // console.log('tops > 1');
      if (tops > 100) {
        $(".back_top").show();
      }
      else{
        $(".back_top").hide();
      }

    } else {
      // $(".logo1").show();
      // $(".logo2").hide();
      $(".header_t").slideDown();
      $(".header_b").removeClass("hover");
      $(".logo").addClass("logo-white");
      $(".menu_dl").addClass("menu_tt_shadow");

      // console.log('tops ==0');
      

    }


  });
  // 防止字体抖动
  FontSize(".soution_one_t_list .item");
  //移动端
  if (w < 1201) {
    //手机站
    $(".mobile_menu_btn").click(function () {
      $(".mobile_menu_mask").fadeIn();
      $(".mobile_menu_box").addClass("show");
      $(".mask").fadeOut();
      $(".mobile_search_box").fadeOut();
    });
    $(".mobile_menu_tt_icon").click(function () {
      $(this).toggleClass("hover");
      $(this)
        .parents("dd")
        .find(".mobile_menu_down")
        .stop(true, true)
        .slideToggle();
    });

    $(".mask").click(function () {
      $(this).fadeOut();
      $(".mobile_search_box").fadeOut();
    });

    $(".mobile_menu_mask").click(function () {
      $(this).fadeOut();
      $(".mobile_menu_box").removeClass("show");
    });
  } else {
    $(".mask").click(function () {
      $(this).fadeOut();
      $(".search_box").fadeOut();
    });
  }
  //公用搜索框的点击事件
  //公用搜索框的点击事件
  public_head_search_fun(".header_search .click_icon");
  $(".header_language_01").on("click", function () {
    $(this).children().toggleClass("hover");
  });
  // 语言切换js
  language_switch(".header_language_01", ".header_language_02");
  // 点击到达指定位置
  if ($(".pro_deTwo1_list").length > 0 && window.innerWidth >= 1025) {
    console.log(window.innerWidth);
    $(window).on("load", function (e) {
      scrollFixed(
        ".pro_deTwo1_list>.item",
        ".pro_deTwo1",
        ".pro_det_scroll_item",
        ".pro_deBoxTwo"
      );
    });
  }

  $(".back_top").click(function () {
    $("body,html").animate(
      {
        scrollTop: "0",
      },
      400
    );
  });
  // 导航下拉
  $(".menu_dl dd").hover(
    function () {
      $(this).find(".menu_down").stop().slideDown();
    },
    function () {
      $(this).find(".menu_down").stop().slideUp();
    }
  );
  $(".menu_dl dd").on("click", function () {
    $(this).addClass("hover").siblings().removeClass("hover");
  });
  // 点击视频
  video_play_fun(".click_video");
  // 弹出表单
  $(".click_popup_inquiry").on("click", function () {
    $(".popup_inquiry_mask").stop().fadeIn();
    $(".popup_inquiry").stop().fadeIn();
  });
  $(".popup_inquiry_mask").on("click", function () {
    $(".popup_inquiry_mask").stop().fadeOut();
    $(".popup_inquiry").stop().fadeOut();
  });
  $(".popup_inquiry_form_close ").click(function () {
    $(".popup_inquiry_mask").stop().fadeOut();
    $(".popup_inquiry").stop().fadeOut();
  });
  //index
  //index
  //index

  if ($(".banner").length > 0) {
    let banner = new Swiper(".banner", {
      loop: true,
      slidesPerView: 1,
      autoplay: {
        delay: 8000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      // 切换效果 淡入
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
    });
  }
  // index_one

  // index_four
  $(".aboBoxFour2 .item").on("mouseenter", function () {
    $(this).stop().addClass("hover").siblings().removeClass("hover");
  });

  if ($(".index_two_swiper").length > 0) {
    var index_two_swiper = new Swiper(".index_two_swiper", {
      loop: true,
      speed: 1300,
      autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1.2,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1025: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1201: {
          slidesPerView: 3.3,
          spaceBetween: 30,
        },
        1411: {
          slidesPerView: 3.3,
          spaceBetween: 40,
        },
        1611: {
          slidesPerView: 3.3,
          spaceBetween: 50,
        },
      },
      navigation: {
        nextEl: ".index_two_swiper_btn .next",
        prevEl: ".index_two_swiper_btn .prev",
      },
    });
  }
  if ($(".index_three_swiper").length > 0) {
    var index_three_swiper = new Swiper(".index_three_swiper", {
      loop: true,
      speed: 1300,
      autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      centeredSlides: true,
      effect: "coverflow",
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 50,
          coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 30,
            modifier: 1,
            slideShadows: false,
          },
        },
        1025: {
          slidesPerView: 2,
          spaceBetween: 50,
          coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 30,
            modifier: 1,
            slideShadows: false,
          },
        },
        1201: {
          slidesPerView: 3,
          spaceBetween: 50,
          coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 30,
            modifier: 1,
            slideShadows: false,
          },
        },
        1411: {
          slidesPerView: 3,
          spaceBetween: 50,
          coverflowEffect: {
            rotate: 0,
            stretch: -50,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          },
        },
        1611: {
          slidesPerView: 3,
          spaceBetween: 50,
          coverflowEffect: {
            rotate: 0,
            stretch: -50,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          },
        },
      },
    });
  }
  //index end
  //index end
  //index end
  //about
  //about
  //about
  table(".factory_list_table .item");
  table(".patent_list_table .item");
  if ($(".suppThree1_R").length > 0) {
    let hover_ind = $(".suppThree1_R").children(".hover").index();
    let hover_bg = $(".suppThree1_R").children(".hover").attr("bg_src");
    $(".suppThree1_L").children().eq(hover_ind).show().siblings().hide();
    $(".suppThree1_R").css("background-image", "url(" + hover_bg + ")");
    $(".suppThree1_R")
      .children()
      .on("mouseenter", function () {
        let this_ind = $(this).index();
        let this_bg = $(this).attr("bg_src");
        $(this)
          .children()
          .siblings()
          .addClass("hover")
          .siblings()
          .removeClass("hover");
        $(this).addClass("hover").siblings().removeClass("hover");
        $(".suppThree1_L").children().eq(this_ind).show().siblings().hide();
        $(".suppThree1_R").css("background-image", "url(" + this_bg + ")");
      });
  } 
  if ($(".about_four_t_swiper").length > 0) {
    var about_four_t_swiper = new Swiper(".about_four_t_swiper", {
      loop: true,
      speed: 1300,
      autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1025: {
          slidesPerView: 4,
        },
        1201: {
          slidesPerView: 4,
        },
        1411: {
          slidesPerView: 5,
        },
        1611: {
          slidesPerView: 6,
        },
      },
      watchSlidesProgress: true,
    });
    let about_four_b_swiper = new Swiper(".about_four_b_swiper", {
      loop: true,
      speed: 1000,
      spaceBetween: 30,
      autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        // disableOnInteraction: true,
        // disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".about_four_t_swiper_btn.next",
        prevEl: ".about_four_t_swiper_btn.prev",
      },
      thumbs: {
        swiper: about_four_t_swiper,
      },
    });
  }
  if ($(".about_eight_swiper").length > 0) {
    var about_eight_swiper = new Swiper(".about_eight_swiper", {
      speed: 1300,
      autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1025: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1201: {
          slidesPerView: 4,
          spaceBetween: 15,
        },
        1411: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
        1611: {
          slidesPerView: 4,
          spaceBetween: 50,
        },
      },
      pagination: {
        el: ".about_eight_swiper_pagination",
        clickable: true,
      },
    });
  }
  //about   end
  //about   end
  //about   end
  //product_del
  //product_del
  //product_del
  if ($(".pro_deOne1_L_lunbo").length > 0) {
    var pro_deOne1_L_dots = new Swiper(".pro_deOne1_L_dots", {
      loop: true,
      speed: 1500,
      autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      direction: "vertical",
      breakpoints: {
        0: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1025: {
          slidesPerView: 3,
        },
        1201: {
          slidesPerView: 3,
        },
        1411: {
          slidesPerView: 3,
        },
        1611: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
    });
    let pro_deOne1_L_lunbo = new Swiper(".pro_deOne1_L_lunbo", {
      spaceBetween: 15,
      loop: true,
      speed: 1500,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      thumbs: {
        swiper: pro_deOne1_L_dots,
      },
      navigation: {
        nextEl: ".pro_del_one_swiper_button.next",
      },
    });
  }
  table(".pro_del_one_swiper_button");
  table(".pro_deOne1_R dl dd .item .colors");
  if ($(".pro_del_eight_swiper").length > 0) {
    var pro_del_eight_swiper = new Swiper(".pro_del_eight_swiper", {
      loop: true,
      speed: 1300,
      autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      centeredSlides: true,
      effect: "coverflow",
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 50,
          coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 30,
            modifier: 1,
            slideShadows: false,
          },
        },
        1025: {
          slidesPerView: 2,
          spaceBetween: 50,
          coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 30,
            modifier: 1,
            slideShadows: false,
          },
        },
        1201: {
          slidesPerView: 3,
          spaceBetween: 50,
          coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 30,
            modifier: 1,
            slideShadows: false,
          },
        },
        1411: {
          slidesPerView: 3,
          spaceBetween: 50,
          coverflowEffect: {
            rotate: 0,
            stretch: -50,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          },
        },
        1611: {
          slidesPerView: 2.4,
          spaceBetween: 50,
          coverflowEffect: {
            rotate: 0,
            stretch: -50,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          },
        },
      },
      navigation: {
        nextEl: ".pro_del_eight_btn .next",
        prevEl: ".pro_del_eight_btn .prev",
      },
    });
  }
  if ($(".pro_del_nine_swiper").length > 0) {
    var pro_del_nine_swiper = new Swiper(".pro_del_nine_swiper", {
      loop: true,
      speed: 1300,
      autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1025: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1201: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
        1411: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
        1611: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      },
    });
  }
  //product_del   end
  //product_del   end
  //product_del   end
  //product_list
  //product_list
  //product_list
  if ($(".pro_three_swiper").length > 0) {
    var pro_three_swiper = new Swiper(".pro_three_swiper", {
      loop: true,
      speed: 1300,
      autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1025: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1201: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1411: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
        1611: {
          slidesPerView: 5,
          spaceBetween: 50,
        },
      },
    });
  }
  //product_list   end
  //product_list   end
  //product_list   end
  if ($(".cover_two_swiper").length > 0) {
    var cover_two_swiper = new Swiper(".cover_two_swiper", {
      loop: true,
      speed: 1300,
      autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      centeredSlides: true,
      effect: "coverflow",
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 30,
          coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 0,
            modifier: 0,
            slideShadows: false,
          },
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 0,
          coverflowEffect: {
            rotate: 0,
            stretch: -50,
            depth: 150,
            modifier: 1,
            slideShadows: false,
          },
        },
        1025: {
          slidesPerView: 2,
          spaceBetween: 20,
          coverflowEffect: {
            rotate: 0,
            stretch: -50,
            depth: 150,
            modifier: 1,
            slideShadows: false,
          },
        },
        1201: {
          slidesPerView: 2,
          spaceBetween: 50,
          coverflowEffect: {
            rotate: 0,
            stretch: -50,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          },
        },
        1411: {
          slidesPerView: 3,
          spaceBetween: 50,
          coverflowEffect: {
            rotate: 0,
            stretch: -50,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          },
        },
        1611: {
          slidesPerView: 3,
          spaceBetween: 50,
          coverflowEffect: {
            rotate: 0,
            stretch: -50,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          },
        },
      },
    });
  }
  if ($(".case_two_swiper").length > 0) {
    var case_two_swiper = new Swiper(".case_two_swiper", {
      loop: true,
      speed: 1300,
      autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1.5,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1025: {
          slidesPerView: 2.5,
          spaceBetween: 20,
        },
        1201: {
          slidesPerView: 2.8,
          spaceBetween: 15,
        },
        1411: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1611: {
          slidesPerView: 3.2,
          spaceBetween: 30,
        },
      },
    });
  }
  if ($(".soution_one_03_swiper").length > 0) {
    var soution_one_03_swiper = new Swiper(".soution_one_03_swiper", {
      loop: true,
      speed: 1300,
      autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1025: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1201: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        1411: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1611: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      },
    });
  }

  if ($(".soution_one_04_swiper").length > 0) {
    var soution_one_04_swiper = new Swiper(".soution_one_04_swiper", {
      loop: true,
      speed: 1300,
      autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1.2,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 2.2,
          spaceBetween: 15,
        },
        1025: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1201: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        1411: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1611: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      },
    });
  }
  //service
  //service
  //service
  if ($(".service_one_b_swiper").length > 0) {
    var service_one_b_swiper = new Swiper(".service_one_b_swiper", {
      loop: true,
      speed: 1300,
      autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        1025: {
          slidesPerView: 3,
          spaceBetween: 60,
        },
        1201: {
          slidesPerView: 4,
          spaceBetween: 60,
        },
        1411: {
          slidesPerView: 4,
          spaceBetween: 60,

        },
        1611: {
          slidesPerView: 5,
          spaceBetween: 60,
        },
      },
    });
    let service_one_t_swiper = new Swiper(".service_one_t_swiper", {
      loop: true,
      speed: 1000,
      spaceBetween: 30,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      thumbs: {
        swiper: service_one_b_swiper,
      },
      navigation: {
        nextEl: ".service_one_btn .next",
        prevEl: ".service_one_btn .prev",
      },
    });
  }

  if ($(".service_Two_swiper").length > 0) {
    var service_Two_swiper = new Swiper(".service_Two_swiper", {
      loop: true,
      speed: 1300,
      autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 60,
        },
        1025: {
          slidesPerView: 3,
          spaceBetween: 60,
        },
        1201: {
          slidesPerView: 3.5,
          spaceBetween: 60,
        },
        1411: {
          slidesPerView: 4,
          spaceBetween: 60,
        },
        1611: {
          slidesPerView: 4.5,
          spaceBetween: 60,
        },
      },
    });
  }
  if ($(".service_three_swiper").length > 0) {
    var service_three_swiper = new Swiper(".service_three_swiper", {
      loop: true,
      speed: 1300,
      autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
      },
      centeredSlides: true,
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 15,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 50,
          coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 30,
            modifier: 1,
            slideShadows: false,
          },
        },
        1025: {
          slidesPerView: 2,
          spaceBetween: 50,
          coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 30,
            modifier: 1,
            slideShadows: false,
          },
        },
        1201: {
          slidesPerView: 3,
          spaceBetween: 30,
          slidesOffsetBefore: -200,
        },
        1411: {
          slidesPerView: 3,
          spaceBetween: 40,
          slidesOffsetBefore: -320,
        },
        1611: {
          slidesPerView: 3,
          spaceBetween: 40,
          slidesOffsetBefore: -320,
        },
      },
      pagination: {
        el: ".service_three_swiper_pagination",
        clickable: true,
      },
    });
  }
  //service   end
  //service   end
  //service   end

});


