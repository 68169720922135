/* 
 * 

 * loadVod 视频加载类
 * 优点：控制视频加载，提升页面加载速度（注意并不能提升视频加载速度，但当视频无效时可以中断加载）
 * 2023-12-11 14:06:36

html：
<video controls class="js-video-loader" preload="metadata" poster="/static/images/video_cover.webp" muted="true" style="width:70%; object-fit: cover;">
  <!-- <source data-src="/uploads/media/20230920/1-2309200ZT42W.webm" type="video/webm"> -->
  <source data-src="/uploads/media/20231208/2-23120Q62631315.mp4" type="video/mp4">
</video>

js:
(new loadVod('js-video-loader'));

 */


export class loadVod {

  // videos=[];

  constructor (vodCssSelector) {
      this.videos = Array.from(document.querySelectorAll(vodCssSelector));
      // 将在下面情况下返回
      // - 浏览器不支持 Promise
      // - 没有 video 元素
      // - 如果用户设置了减少动态偏好（prefers reduced motion） 
      // - 在移动设备上
      if (typeof Promise === 'undefined'
        || !this.videos
        || window.matchMedia('(prefers-reduced-motion)').matches
        // || window.innerWidth < 992
      ) {
        return;
      }

      this.videos.forEach(this.loadVideo.bind(this));
    }



    // 视频加载方法
    loadVideo(video) {
      this.setSource(video);
      video.load(); // 加上了视频链接后重新加载视频
      this.checkLoadTime(video);
    }


  /**
    * 找 video 子元素中是 <source> 的，
    * 基于 data-src 属性，
    * 给每个 <source> 设置 src 属性
    *
    * @param {DOM Object} video
    */
    setSource (video) {
      let children = Array.from(video.children);
      children.forEach(child => {
        if (child.tagName === 'SOURCE' && typeof child.dataset._src !== 'undefined') {
          child.setAttribute('src', child.dataset._src);
          
        }
      });
    }



    // 视频检查 
    checkLoadTime(video){

      // 创建一个 Promise，将在
      // video.canplaythrough 事件发生时被 resolve
      let videoLoad = new Promise((resolve) => {
        video.addEventListener('canplaythrough', () => {
          resolve('can play');
        });
      });

      // 创建一个 Promise 将在
      // 特定时间(2s)后被 resolve
      let videoTimeout = new Promise((resolve) => {
        setTimeout(() => {
          resolve('The video timed out.');
        }, 5000);
      });


    // 将 promises 进行 Race 看看哪个先被 resolves
    Promise.race([videoLoad, videoTimeout]).then(data => {
      if (data === 'can play') {

        video.play();//播放

        // console.log('play started!');

        setTimeout(() => {
          video.classList.add('video-loaded');
        }, 3000);

      } else {
        this.cancelLoad(video);
      }
    });

  }


  /**
    * 通过移除所有的 <source> 来取消视频加载
    * 然后触发 video.load().
    *
    * @param {DOM object} video
    */
    cancelLoad (video) {

      let children = Array.from(video.children);

      children.forEach(child => {
        if (child.tagName === 'SOURCE' && typeof child.dataset._src !== 'undefined') {
          child.parentNode.removeChild(child);
        }
      });

      // 重新加载没有 <source> 标签的 video
      // 这样它会停止下载
      console.log('cancel');
      video.load();

    }

}

// export loadVod;



// setTimeout(()=>{
//   new loadVod('.js-video-loader');
// }, 3000)
