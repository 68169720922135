
require('../style/reset.css');
require('../style/fonts.css');
require('../style/swiper.min.css');
require('../style/style.css');
require('../style/tailwind_common.css');
require('../style/animate.min.css');
require('../style/layout.css');


require('../js/page-common.js');




// 小窗口打开
window.openWin = function(url='https://www.baidu.com',name="_blank",iWidth=400,iHeight=500){
  var url;                            //转向网页的地址;
  var name;                           //网页名称，可为空;
  var iWidth;                         //弹出窗口的宽度;
  var iHeight;                        //弹出窗口的高度;
  //window.screen.height获得屏幕的高，window.screen.width获得屏幕的宽
  var iTop = (window.screen.height-30-iHeight)/2;       //获得窗口的垂直位置;
  var iLeft = (window.screen.width-10-iWidth)/2;        //获得窗口的水平位置;
  window.open(url,name,'height='+iHeight+',,innerHeight='+iHeight+',width='+iWidth+',innerWidth='+iWidth+',top='+iTop+',left='+iLeft+',popup=1,toolbar=no,menubar=no,scrollbars=auto,resizeable=no,location=no,status=no');
}

// 打开在线客服 2024-01-22 13:48:07
window.openChatWin = function(isLink){

  var url = "https://lr.zoosnet.net/LR/Chatpre.aspx?id=LEF44032024&cid=d2c9e287b5f5460ebbd09c6f949b7353&lng=en";
  // var url = "https://drt.zoosnet.net/LR/Chatpre.aspx?id=DRT31822798&cid=ba7e0b355c264d049bb4773bccdcc597&lng=en";

  // 是链接则直接打开，否则小窗口打开
  isLink ? window.open(url) : openWin(url);
}


const online_kefu = document.querySelectorAll(".open_online_kefu");

if(online_kefu){

  // console.log(online_kefu);

  for(let _node of online_kefu){

    _node.addEventListener("click", function(evt){
      evt.preventDefault();
      console.log('open new wid');
      openChatWin();
      // openWin("https://lr.zoosnet.net/LR/Chatpre.aspx?id=LEF44032024&cid=d2c9e287b5f5460ebbd09c6f949b7353&lng=en");
    });    

  }

}



